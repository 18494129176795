import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import descriptionData from "../content/description.json";
import ProductContainer from "../components/productContainer";

const About = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  return (
    <Layout>
      <Seo
        title="About Collective Tops"
        description="Collective Tops offers a curated selection of latest dress styles from leading designers and brands, providing a time-efficient shopping experience. Our comprehensive collection serves all your dress needs, making us the ultimate one-stop-shop for fashionable attire."
      />

      <div className="descriptions-container container px-18 max-w-screen-xl mx-auto">
        <h1 class="text-2xl mt-5 mb-5 font-bold">About Collective Tops</h1>
        <p>
        Collective Tops is your ultimate fashion destination that alleviates the stress of incessant shopping by collating the most fashionable dresses from different stores into one convenient platform. 
        </p>

        <p>
        Our collection spans the upscale and recent dress styles, offering a vast array, whether it's crop tops, corset tops, sleeveless tops, or more. We strive to cater to a myriad of styles and preferences across different occasions.
        </p>

        <p>
        In addition to our comprehensive tops collection, Collective Tops doubles up as a fashion consultancy and an inspiration hub. Our blog is regularly updated with informative content featuring expert fashion insights, interviews, and guides.
        </p>

        <p>
        Visit Collective Tops today and let us help you find a dress that makes you not just look, but also feel incredible!
        </p>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "archive_dress_party" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
  }
`;

export default About;
